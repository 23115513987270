<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t('Copla.Views.Synthesis.Title') }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t('Copla.Common.Subtitles.Configuration') }}</h2>
          <div class="form-group row container">
            <label for="server" class="col-2 col-form-label">{{
              $t('Copla.Common.Form.Language')
            }}</label>
            <div class="col-10">
              <select v-model="language" class="custom-select">
                <option
                  v-bind:value="id"
                  v-for="(value, id) in filteredLanguages"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label for="server" class="mt-2 col-2 col-form-label">{{
              $t('Copla.Common.Form.Voices')
            }}</label>
            <div class="col-10 mt-2">
              <select v-model="voice" class="custom-select">
                <option
                  :value="id"
                  v-for="(value, id) in voicesSelect"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4" id="immediate">
          <h2>{{ $t('Copla.Views.Synthesis.Immediate') }}</h2>
          <div class="form-group">
            <textarea
              class="form-control"
              v-model="text"
              :placeholder="placeholder"
              rows="5"
            ></textarea>
          </div>
          <div class="row mt-3">
            <div class="col mr-3">
              <div class="text-center">
                <button
                  type="button"
                  id="play"
                  name="play"
                  class="btn btn-success"
                  v-on:click="play(text, '')"
                >
                  {{ $t('Copla.Common.Buttons.Play') }}
                </button>
                <button
                  type="button"
                  id="download"
                  name="download"
                  class="btn ml-3 btn-primary"
                  v-on:click="download(text, '')"
                >
                  {{ $t('Copla.Common.Buttons.Download') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4">
          <h2>{{ $t('Copla.Views.Synthesis.ScriptLoad') }}</h2>
          <div class="form-group row">
            <label for="file" class="col-2 col-form-label text-center">
              {{ $t('Copla.Views.Synthesis.AudioScript') }} (.sti/.srt)
            </label>
            <div class="col-7">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".sti,.srt"
                required
              />
            </div>
            <div class="text-center">
              <button
                type="button"
                id="upload"
                name="upload"
                class="btn btn-primary"
                v-on:click="uploadFile"
                v-if="file != ''"
              >
                {{ $t('Copla.Common.Buttons.Load') }}
              </button>
              <button
                type="button"
                id="downloadZip"
                name="downloadZip"
                class="btn btn-primary ml-2"
                v-on:click="downloadZip"
                v-if="script.length > 0"
              >
                {{ $t('Copla.Common.Buttons.DownloadZIP') }}
              </button>
            </div>
          </div>
          <div
            v-if="error != ''"
            class="border rounded secciones mt-4"
            id="error"
          >
            {{ error }}
          </div>
        </div>
        <table
          class="table table-sm table-striped table-dark mt-4"
          v-if="script.length > 0"
        >
          <thead>
            <tr class="d-flex">
              <th class="col col-xl-9">
                {{ $t('Copla.Views.Synthesis.Table.Text') }}
              </th>
              <th class="col col-xl-3">
                {{ $t('Copla.Views.Synthesis.Table.Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(text, i) in script" :key="i" :id="i" class="d-flex">
              <td class="col col-xl-9" style="vertical-align: top">
                {{ text }}
              </td>
              <td class="col col-xl-3">
                <button
                  type="button"
                  v-on:click="playScript(i)"
                  class="btn btn-success"
                >
                  {{ $t('Copla.Common.Buttons.Play') }}
                </button>
                <button
                  type="button"
                  v-on:click="downloadScript(i)"
                  class="btn btn-primary ml-2"
                >
                  {{ $t('Copla.Common.Buttons.Download') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
//import axios from "axios";
import MainMenu from '../components/MainMenu'
import AzureSynthesizer from '../js/AzureSynthesizer'
import AzureAPISynthesizer from '../js/AzureAPISynthesizer'
import formatSubtitlesAPI from '../js/formatSubtitlesAPI'
import JSZip from 'jszip'
import ModalLoading from '../components/ModalLoading.vue'
export default {
  name: 'Synthesis',
  components: {
    MainMenu,
    ModalLoading
  },
  data () {
    return {
      text: '',
      languages: this.$t('Copla.Common.Lists.Languages'),
      filteredLanguages: {},
      voices: [],
      voicesSelect: {},
      language: 'es-ES',
      voice: '',
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: '',
      script: [],
      showModalLoading: false,
      messageModal: '',
      error: '',
      loading: 0,
      percentage: 0,
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      placeholder: this.$t('Copla.Views.Synthesis.Placeholder')
    }
  },
  mounted () {
    let self = this
    let azureAPISynthesizer = new AzureAPISynthesizer({
      key: process.env.VUE_APP_AZURE_KEY,
      location: process.env.VUE_APP_AZURE_LOCATION
    })
    azureAPISynthesizer.getVoicesList().then(response => {
      self.voices = response.data.locale
      self.voice = self.voices[self.language][0].ShortName
      self.filteredLanguages = Object.keys(self.languages)
        .filter(key => Object.prototype.hasOwnProperty.call(self.voices, key))
        .reduce((acc, key) => {
          acc[key] = self.languages[key]
          return acc
        }, {})
      const entries = Object.entries(self.filteredLanguages)
      entries.sort(([, value1], [, value2]) => value1.localeCompare(value2))
      self.filteredLanguages = entries.reduce((acc, curr) => {
        acc[curr[0]] = curr[1]
        return acc
      }, {})
    })
  },
  watch: {
    voices: function (val) {
      if (val.length == 0) {
        this.voicesSelect = {}
      } else {
        let self = this
        let list = self.voices[self.language]
        let newList = {}
        list.forEach(function (value, index) {
          let name = value.Name + ' (' + value.Gender + ')'
          let key = value.ShortName
          newList[key] = name
          if (index == 0) {
            self.voice = key
          }
        })
        this.voicesSelect = newList
      }
    },
    language: function (val) {
      let self = this
      let list = self.voices[val]
      let newList = {}
      list.forEach(function (value, index) {
        let name = value.Name + ' (' + value.Gender + ')'
        let key = value.ShortName
        newList[key] = name
        if (index == 0) {
          self.voice = key
        }
      })
      this.voicesSelect = newList
    }
  },
  methods: {
    showModal (status, message = '') {
      this.messageModal = message
      this.showModalLoading = status
    },
    uploadFile: function () {
      this.showModal(true, this.$t('Copla.Common.Modal.Uploading'))
      let formData = new FormData()
      let splitFile = this.file.name.split('.')
      let extension = splitFile[splitFile.length - 1]
      formData.append('_file', this.file)
      this.fsAPI.uploadFile(formData).then(response => {
        if (!response.error) {
          this.showModal(false)
          if (extension == 'srt') {
            this.postSRT(response.data.data)
          } else if (extension == 'sti') {
            this.postStartit(response.data.data)
          }
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    postSRT: function (url) {
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      let params = {
        _url: url
      }
      this.fsAPI.postSRT(params).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          this.getSubtitles(response.data.data.caf)
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    postStartit: function (url) {
      let self = this
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      let params = {
        _url: url,
        _language: self.language
      }
      this.fsAPI.postStartit(params).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          this.getAudiodescriptions(response.data.data.caf)
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    getSubtitles: function (id) {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.fsAPI.getSubtitles(id).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          let text = ''
          response.data.data.forEach(function (subs) {
            subs.lines.forEach(function (sub) {
              text += sub.text + ' '
            })
            self.script.push(text)
            text = ''
          })
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    getAudiodescriptions: function (id) {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.fsAPI.getAudiodescriptions(id).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          response.data.data.forEach(function (ads) {
            self.script.push(ads.text)
          })
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    downloadZip: function () {
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      this.percentage = 0
      this.showModal(
        true,
        this.$t('Copla.Common.Modal.Creating') + ' ' + this.percentage + '%'
      )
      var zip = new JSZip()
      let self = this
      let count = 0
      this.script.forEach(function (value, i) {
        let file = i + 1
        if (file < 10) {
          file = '00' + file
        } else if (file < 100) {
          file = '0' + file
        }
        let fileName = file + '.wav'
        let params = { mode: 1, text: value }
        params.language = self.language
        params.voice = self.voice
        let azureSynth = new AzureSynthesizer(params)
        azureSynth.createFile().then(response => {
          var blob = new Blob([response], {
            type: 'audio/wav'
          })
          zip.file(fileName, blob)
          count++
          self.percentage = Math.round((count * 100) / self.script.length)
          self.showModal(
            true,
            self.$t('Copla.Common.Modal.Creating') + ' ' + self.percentage + '%'
          )
          if (count >= self.script.length) {
            self.showModal(true, self.$t('Copla.Common.Modal.Downloading'))
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              let url = URL.createObjectURL(content)
              let element = document.createElement('a')
              element.setAttribute('href', url)
              element.setAttribute('download', 'AD.zip')
              element.style.display = 'none'
              element.click()
              self.showModal(false)
            })
          }
        })
      })
    },
    playScript: function (i) {
      let file = i + 1
      if (file < 10) {
        file = '00' + file
      } else if (file < 100) {
        file = '0' + file
      }
      let fileName = file + '.wav'
      this.play(this.script[i], fileName)
    },
    downloadScript: function (i) {
      let file = i + 1
      if (file < 10) {
        file = '00' + file
      } else if (file < 100) {
        file = '0' + file
      }
      let fileName = file + '.wav'
      this.download(this.script[i], fileName)
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    downloadFile: function (data, fileName) {
      var blob = new Blob([data], {
        type: 'audio/wav; codecs=MS_PCM'
      })
      let url = URL.createObjectURL(blob)
      let element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', fileName)
      element.style.display = 'none'
      element.click()
    },
    play: function (text, fileName) {
      if (fileName == '') {
        fileName = 'audio.wav'
      }
      if (text != '') {
        let params = { mode: 0, text: text }
        this.connect(params, fileName)
      }
    },
    download: function (text, fileName) {
      if (fileName == '') {
        fileName = 'audio.wav'
      }
      if (text != '') {
        let params = { mode: 1, text: text }
        this.connect(params, fileName)
      }
    },
    connect: function (params, fileName) {
      let self = this
      params.language = this.language
      params.voice = this.voice
      let azureSynth = new AzureSynthesizer(params)
      azureSynth.createFile().then(response => {
        if (params.mode == 1) {
          self.downloadFile(response, fileName)
        }
      })
    }
  }
}
</script>

<style scoped>
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}

.loading {
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#error {
  font-size: 1.2em;
  color: red;
  font-weight: bold;
}
</style>
